import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Assets } from '../models/assets.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  
  baseUrl = environment.apiserver;;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }
  
  saveAssets(data: any): Observable<Assets> {
    let url = `${this.baseUrl}/assets/create`; debugger;
    if (data['id'] >0) {
      url = `${this.baseUrl}/assets/update/` + data['id'];
    }


    return this.httpClient.post<Assets>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAssets(id?: number, page?: number): Observable<Assets[]> {
    let url = `${this.baseUrl}/assets?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/assets/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/assets?page=` + page
    }

    return this.httpClient.get<Assets[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  getAssetsStatus(id?: number, page?: number): Observable<Assets[]> {
    let url = `${this.baseUrl}/assetsstatus?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/assetsstatus/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/assetsstatus?page=` + page
    }

    return this.httpClient.get<Assets[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }
  
  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  addAssets(data: any): Observable<Assets> {
    let url = `${this.baseUrl}/assets/create`; debugger;
    if (data['id'] != 0) {
      url = `${this.baseUrl}/assets/update/` + data['id'];
    }

    return this.httpClient.post<Assets>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
   }
   
}
