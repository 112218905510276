import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Instructor } from '../models/instructor.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstructorService {
 


  baseUrl = environment.apiserver;;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }


  saveInstructor(data: any): Observable<Instructor> {
    let url = `${this.baseUrl}/instructor/create`; debugger;
    if (data['id'] != 0) {
      url = `${this.baseUrl}/instructor/update/` + data['id'];
    }


    return this.httpClient.post<Instructor>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  saveInstructorPicture(data){
    let url = `${this.baseUrl}` + "/instructor/profilepic";    

    
    return this.httpClient.post(url, data)
      .pipe(
        retry(0),
        catchError(this.handleError)
      )
  }  



  addInstructor(data: any): Observable<Instructor> {
    let url = `${this.baseUrl}/instructor/create`; debugger;
    if (data['id'] != 0) {
      url = `${this.baseUrl}/instructor/update/` + data['id'];
    }

    return this.httpClient.post<Instructor>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
   }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  
  getInstructor(id?: number, page?: number): Observable<Instructor[]> {
    let url = `${this.baseUrl}/instructor?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/instructor/` + id;
    }
     else if (page > 0) {
      url = `${this.baseUrl}/instructor?page=` + page
    }

    return this.httpClient.get<Instructor[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  addAttendance(data: any): Observable<Instructor> {
    let url = `${this.baseUrl}/instructorattendance/create`; debugger;
    if (data['id'] != 0) {
      url = `${this.baseUrl}/instructorattendance/update/` + data['id'];
    }

    return this.httpClient.post<Instructor>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
   }
  getAttendance(id?: number, page?: number): Observable<Instructor[]> {
    let url = `${this.baseUrl}/instructorattendance?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/instructorattendance/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/instructorattendance?page=` + page
    }

    return this.httpClient.get<Instructor[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }
  searchAttendance(data: any): Observable<Instructor>{
    let url = `${this.baseUrl}/instructorattendance/search`; debugger;
    
    return this.httpClient.post<Instructor>(url, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
}